@import 'base';

//頁尾  20181025 Rock
footer{
    margin-top: 20px;
    padding:0 0 20px 0;
    background-color: $ansforce_blue;
    color:#fff;
    height: 400px;
    > div:first-of-type{
        height: 30px;
        background-color: $standard_gray;
    }
    > .main-container{
      //margin: 0 calc( 50% - 600px );
      min-height: 300px;
        a{
            text-decoration: none;
            color: white;
        }
        //height: 350px;
        div{
            //margin:10px;
            font-size: 16px;
            max-height: 100%;
            display: inline-block;
            //右邊部分
            > p:first-of-type{
                font-weight: bold !important;
                margin:10px 0;
            }
            p{
                line-height: 24px;
                margin: 5px auto;
            }
        }
        //左邊比較大塊的部分
        > div:first-of-type{
            span{
                display: block;
            }
            font-size: 32px;
            line-height: 50px;
            //width: 450px;
            margin-top: 40px;
        }
    }
    //下方隱私政策等等
    .footer-bot-block{
        text-align: center;
        div{
            margin: 10px 0;
            span{
                margin:0 10px;
            }
        }
        a{
            text-decoration: none;
            color: white;
        }
    }
}
@media screen and (max-width: 1280px) {
  footer{
    height: auto;
  }
}
